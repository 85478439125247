import {
    getAdminProfileAPI, updateAdminProfileAPI,
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ADMIN_PROFILE_REQUEST = 'GET_ADMIN_PROFILE_REQUEST';
export const GET_ADMIN_PROFILE_SUCCESS = 'GET_ADMIN_PROFILE_SUCCESS';
export const GET_ADMIN_PROFILE_FAILED = 'GET_ADMIN_PROFILE_FAILED';

export const getAdminProfile = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ADMIN_PROFILE_REQUEST
        })
        getAdminProfileAPI(filters).then(res => {
            dispatch({
                type: GET_ADMIN_PROFILE_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ADMIN_PROFILE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const UPDATE_ADMIN_PROFILE_REQUEST = 'UPDATE_ADMIN_PROFILE_REQUEST';
export const UPDATE_ADMIN_PROFILE_SUCCESS = 'UPDATE_ADMIN_PROFILE_SUCCESS';
export const UPDATE_ADMIN_PROFILE_FAILED = 'UPDATE_ADMIN_PROFILE_FAILED';


export const updateAdminProfile = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_ADMIN_PROFILE_REQUEST
        })
        updateAdminProfileAPI(data).then(res => {
            console.log("res==>",res)
            dispatch({
                type: UPDATE_ADMIN_PROFILE_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_ADMIN_PROFILE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })