import { apiGet, apiPost, apiPut, apiDelete, getDeviceType } from '../../../utils';

const ADD_CUS_MEMBER = '/api/customer/property/add-member'

export function getAllCoustomerAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }
    return apiGet(`/api/customer/get/all?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)

}

export function getCustomerDetailAPI(id) {
    return apiGet(`/api/customer/get-info?customer_id=${id}`)
}

export function deleteCustomerAPI(id) {
    return apiDelete(`/api/customer/delete?customer_id=${id}`)
}

export function getPropertyDetailsAPI(id) {
    return apiGet(`/api/customer/property/get-info?property_id=${id}`)
}

export function addPropertyMemberAPI(data) {
    return apiPost(ADD_CUS_MEMBER, data)
}

export function updatePropertyDetailAPI(data) {

    const { id = '', updatedRegisteredAddress = {}, surveyQuestion = [], updatedTariffDetail = {} } = data

    let obj = {
        id: id,
        property_updated: updatedRegisteredAddress?.property_updated ? true : false,
        unit_type: updatedRegisteredAddress?.unit_type?.value || '',
        address_type: updatedRegisteredAddress?.address_type?.value || '',
        unit_number: updatedRegisteredAddress?.unit_number || '',
        level_type: updatedRegisteredAddress?.level_type?.value || '',
        level_number: updatedRegisteredAddress?.level_number || '',
        property_name: updatedRegisteredAddress?.property_name || '',
        city: updatedRegisteredAddress?.city || '',
        street: updatedRegisteredAddress?.street || '',
        street_number: updatedRegisteredAddress?.street_number || '',
        street_type: updatedRegisteredAddress?.street_type?.value || '',
        street_suffix: updatedRegisteredAddress?.street_suffix?.value || '',
        state: updatedRegisteredAddress?.state?.value || '',
        postal_code: updatedRegisteredAddress?.postal_code || '',
        survey_data: surveyQuestion,

        customer_tariff_details: {
            property_id: id,
            tariff_structure_id: updatedTariffDetail?.selectedTarrifStructure?.value || '',
            electricity_retailer_id: updatedTariffDetail?.seletedElectricityRetailer?.value || '',
            gst_include: updatedTariffDetail.gstIncluded === true ? 1 : 0,
            discount_percentage: updatedTariffDetail?.discountPercent ? updatedTariffDetail?.discountPercent : 0,
            discount_type: updatedTariffDetail?.discount_type,
            supply_charge: updatedTariffDetail?.supplyCharge ? updatedTariffDetail?.supplyCharge : 0,
            supply_charge_discount: updatedTariffDetail?.supplyChargeDiscount ? 1 : 0,
            impluse_rate: updatedTariffDetail?.impluse_rate,
            unit_charge: updatedTariffDetail?.isFlatRate ? updatedTariffDetail?.costPerKwh : 0,
            same_as_weekdays: 0,
            company_id: updatedTariffDetail?.selectedElectricityDistributor?.value || '',
            company_name: updatedTariffDetail?.selectedElectricityDistributor?.title || '',
            is_flat_rate: updatedTariffDetail?.isFlatRate,
            tariff_structure_type: updatedTariffDetail?.selectedTarrifStructure?.title || '',
            tariff_plan: !updatedTariffDetail?.isFlatRate ? updatedTariffDetail?.tariffPlan?.length ?
                updatedTariffDetail.tariffPlan.map(res => ({ key: res.key, load_type_key: res.load_type_key, unit_charge: res.unit_charge || '' })) : [] : [],
            rates_updated: updatedTariffDetail?.rates_updated ? true : false
        }
    }
    return apiPut('/api/customer/property/update-details', obj)
}


export function updateCustomerDetailAPI(data) {
    const { id = '', customerDetail = {} } = data
    const { first_name = '', last_name = '', phone_no = '', email } = customerDetail || {}
    let obj = {
        customer_id: id,
        first_name,
        last_name,
        phone_no,
        email
        // premium
    }
    return apiPut('/api/customer/update', obj)
}

export function electricityRateHistoryAPI(id) {
    return apiGet(`/api/customer/property/tariff-history?property_id=${id}`)
}

export function deletePropertyAPI(id) {
    return apiDelete(`/api/customer/property/delete?property_id=${id}`)
}

export function getCusLoginPayloadForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/customer/login-data?customer_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`)
}

export function getCusAdminPropertiesForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/customer/property/list?customer_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`)
}

export function getCusSharedPropertiesForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/customer/property/shared-list?customer_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`)
}

export function getCusPropertyMembersForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/customer/property/member?property_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`)
}

export function getCusPropertyDevicesForTableAPI(filters, type) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/customer/property/devices?property_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&type=${type}`)
}

export function getCustomerEmailHistoryAPI(id) {
    return apiGet(`/api/customer/email-history?customer_id=${id}`)
}

export function deleteMemberAPI(obj) {
    return apiDelete(`/api/customer/property/remove-member`, obj)
}

export function downloadCSVAllCustomersAPI() {
    return apiGet(`/api/export/customers?deviceType=${getDeviceType()}`)
}

export function downloadCSVTrialCustomersAPI() {
    return apiGet('/api/export/feedback')
}

export function getPropertyTimelineHistoryAPI(id) {
    return apiGet(`/api/customer/property/timeline?property_id=${id}`)
}

export function getTrialHistoryAPI(id, syncType) {
    return apiGet(`/api/customer/feedback-history?customer_id=${id}&sync_type=${syncType.synctype}`)
}

export function downloadCSVTrialforSingleCustomerAPI(id) {
    return apiGet(`/api/customer/export-feedback?customer_id=${id}`)
}

export function downloadAutoSyncFeedbackFormAPI(id) {
    return apiGet(`/api/customer/export-feedback-email?customer_id=${id}`)
}

export function downloadAutoSyncFormCsvAPI() {
    return apiGet('/api/export/feedback-all')
}









