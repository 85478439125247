import React, { Suspense } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import "./assets/scss/index.scss";
import { useSelector, useDispatch } from "react-redux"
import * as commonActions from "../src/containers/common/actions"
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import history from './components/common/history'

// common function 
import { isLoggedIn } from "./utils"
import CustomDialogBox from './components/common/CustomDialogBox';
import TokenExpiredModal from './components/common/TokenExpiredModal';
import Rules from './containers/Admin/OTARules';
import OTAGroups from './containers/Admin/OTAGroups';
import OTAGroup from './containers/Admin/OTAGroups/Group';
import CustomGlobalDialog from './components/common/CommonGlobalModal/index';
import LightingDevices from './containers/Admin/LightingDevices';

// public pages
const PublicMain = React.lazy(() => import('./containers/PublicMain'));
const Login = React.lazy(() => import('./containers/Auth/Login/index'));
const ElectricianLogin = React.lazy(() => import('./containers/Auth/ElectricianLogin/index'));
const ElectricianForgotPassword = React.lazy(() => import('./containers/Auth/ElectricianForgotPassword'))
const ElectricianResetPassword = React.lazy(() => import('./containers/Auth/ElectricianResetPassword'))
const Signup = React.lazy(() => import('./containers/Auth/Signup/index'));
const Main = React.lazy(() => import('./containers/Main'));
const ForgotPassword = React.lazy(() => import('./containers/Auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./containers/Auth/ResetPassword'));
const NotFound = React.lazy(() => import('./containers/NotFound'));
const OTPInput = React.lazy(() => import('./components/common/OTPInput'));


// Admin pages
const Dashboard = React.lazy(() => import('./containers/Admin/Dashboards/index'))
const Devices = React.lazy(() => import('./containers/Admin/Devices'));
const UpdateRateHistory = React.lazy(() => import('./containers/Admin/Devices/Device/UpdateRateHistory/index'))
const AdminDevice = React.lazy(() => import('./containers/Admin/Devices/Device'));
const LiveLinkDevices = React.lazy(() => import('./containers/Admin/LiveLinkDevices'))
const LiveLinkDevice = React.lazy(() => import('./containers/Admin/LiveLinkDevices/LiveLinkDevice/index'))
const LiveLinkDeviceHistory = React.lazy(() => import('./containers/Admin/LiveLinkDevices/LiveLinkDevice/DeviceHistory'))
const Customers = React.lazy(() => import('./containers/Admin/Customers'));
const AutoSync = React.lazy(() => import('./containers/Admin/Customers/AutoSync'));
const ShowCustomer = React.lazy(() => import('./containers/Admin/Customers/Customer/ShowCustomer'));
const CustomerEmailHistory = React.lazy(() => import('./containers/Admin/Customers/Customer/CustomerEmailHistory'));
const Property = React.lazy(() => import('./containers/Admin/Customers/Customer/Property/index'));
const PropertyHistory = React.lazy(() => import('./containers/Admin/Customers/Customer/Property/PropertyHistory'));
const PropertyTimeLine = React.lazy(() => import('./containers/Admin/Customers/Customer/Property/PropertyTimeline'));
const InstallerAgencies = React.lazy(() => import('./containers/Admin/InstallerAgencies'));
const ShowAgency = React.lazy(() => import('./containers/Admin/InstallerAgencies/agency/ShowAgency'));
const AddAgency = React.lazy(() => import('./containers/Admin/InstallerAgencies/agency/AddAgency'));
const Integrations = React.lazy(() => import('./containers/Admin/Integrations'));
const ShowIntegration = React.lazy(() => import('./containers/Admin/Integrations/Integration/ShowIntegration'));
const InstallerAgents = React.lazy(() => import('./containers/Admin/InstallerAgents'));
const InstallerAgent = React.lazy(() => import('./containers/Admin/InstallerAgents/InstallerAgent/index'));
const InstallerAgentDevice = React.lazy(() => import('./containers/Admin/InstallerAgents/InstallerAgent/ShowDevice'));
const Tariffs = React.lazy(() => import('./containers/Admin/Tariffs'));
const CreateTariffPage = React.lazy(() => import('./containers/Admin/Tariffs/Tariff/AddTariff'));
const ElectricityRetailer = React.lazy(() => import('./containers/Admin/ElectricityRetailers'));
const Firmwares = React.lazy(() => import('./containers/Admin/Firmwares'))
const OTAs = React.lazy(() => import('./containers/Admin/OTAs'))
const OTAHistroy = React.lazy(() => import('./containers/Admin/OTAHistory'))
const FirmwareHistory = React.lazy(() => import('./containers/Admin/Devices/Device/FirmwareHistory'))
const ImpulseRateHistory = React.lazy(() => import('./containers/Admin/Devices/Device/ImpulseRateHistory'))
const DeviceHistory = React.lazy(() => import('./containers/Admin/Devices/Device/DeviceHistory'))
const SuppressionList = React.lazy(() => import('./containers/Admin/WeeklyReports'))
const Electrician = React.lazy(() => import('./containers/Admin/Electricians/index'))
const ShowElectrician = React.lazy(() => import('./containers/Admin/Electricians/Electrician/index'))
const AddElectrician = React.lazy(() => import('./containers/Admin/Electricians/Electrician/AddElectrician'))
const ElectriciansAgents = React.lazy(() => import('./containers/Admin/ElectricianInstallers/index'))
const ElectricianAgent = React.lazy(() => import('./containers/Admin/ElectricianInstallers/ElectricianInstaller/index'))
const Aggregators = React.lazy(() => import('./containers/Admin/Aggregators/index'))
const SafelinkDevices = React.lazy(() => import('./containers/Admin/SafelinkDevices/index'))
const SafelinkDevice = React.lazy(() => import('./containers/Admin/SafelinkDevices/SafelinkDevice/index'))
const SmokeAlarmDevices = React.lazy(() => import('./containers/Admin/SmokeAlarmDevices/index'))
const SmokeAlarmDevice = React.lazy(() => import('./containers/Admin/SmokeAlarmDevices/SmokeAlarmDevice/index'))
const SafelinkDeviceHistory = React.lazy(() => import('./containers/Admin/SafelinkDevices/SafelinkDevice/DeviceHistory'))
const AirlinkDeviceHistory = React.lazy(() => import('./containers/Admin/AirlinkDevices/AirlinkDevice/AirlinkDeviceHistory'))
const AirlinkDevices = React.lazy(() => import('./containers/Admin/AirlinkDevices'))
const AirlinkDevice = React.lazy(() => import('./containers/Admin/AirlinkDevices/AirlinkDevice'))
const LightingDevice = React.lazy(() => import('./containers/Admin/LightingDevices/LightingDevice'))
const LightingDeviceHistory = React.lazy(() => import('./containers/Admin/LightingDevices/LightingDevice/LightingDeviceHistory'))
const AllTabDevices = React.lazy(() => import('./containers/Admin/AllDevicesTab'))

const HeatPumpDevice = React.lazy(() => import('./containers/Admin/HeatPumpDevices/HeatPumpDevice'))
const HeatpumpModels = React.lazy(() => import('./containers/Admin/HeatpumpModels'))

const HeatPumpDevices = React.lazy(() => import('./containers/Admin/HeatPumpDevices'))
const HeatPumpDeviceHistory = React.lazy(() => import('./containers/Admin/HeatPumpDevices/HeatPumpDevice/HeatPumpDeviceHistory'))

const AdminProfile = React.lazy(() => import('./containers/Admin/Profile'))
const Slider = React.lazy(() => import('./containers/Admin/Slider'))

// Agency pages
const AgencyDevices = React.lazy(() => import('./containers/Agency/AgencyDevices'));
const ShowAgencyDevice = React.lazy(() => import('./containers/Agency/AgencyDevices/Device/showDevice'));
const DataForceAgencyDevices = React.lazy(() => import('./containers/Agency/DataForceAgencyDevices/index'));
const DataForceAgencyElectricians = React.lazy(() => import('./containers/Agency/DataForceAgencyElectricians/index'));
const ShowDataForceAgencyElectrician = React.lazy(() => import('./containers/Agency/DataForceAgencyElectricians/DataForceAgencyElectrician/index'));
const ShowDataForceAgencyAgents = React.lazy(() => import('./containers/Agency/DataForceAgencyAgents'));
const ShowDataForceAgencyAgent = React.lazy(() => import('./containers/Agency/DataForceAgencyAgents/DataForceAgencyAgent/index'));
const InstallationAgents = React.lazy(() => import('./containers/Agency/InstallationAgents'));
const AddInstallationAgent = React.lazy(() => import('./containers/Agency/InstallationAgents/Agent/AddAgent'));
const ShowInstallationAgent = React.lazy(() => import('./containers/Agency/InstallationAgents/Agent/ShowAgent'));
const Profile = React.lazy(() => import('./containers/Agency/Profile'));


// Electrician pages
const ElectricianDevices = React.lazy(() => import('./containers/Electrician/Devices/index'));
const ElectricianDevice = React.lazy(() => import('./containers/Electrician/Devices/Device/index'));
const ElectricianInstallerAgent = React.lazy(() => import('./containers/Electrician/InstallerAgents/index'));
const AddElectricianInstallerAgent = React.lazy(() => import('./containers/Electrician/InstallerAgents/InstallerAgent/AddAgent'));
const ShowElectricianInstallerAgent = React.lazy(() => import('./containers/Electrician/InstallerAgents/InstallerAgent/index'));
const ElectricianProfile = React.lazy(() => import('./containers/Electrician/Profile'));


// Common Pages
const ShowElectricianDeviceHistoryDetails = React.lazy(() => import('./containers/commonPages/Pages/CommonDeviceDetail'))


const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const checkAuth = (userType) => {
    return isLoggedIn(userType);
};

// Check Public Routes
const PublicRoutes = ({ component: Component, path, ...rest }) => {
    let user = checkAuth('access-token');
    let { isAuthenticated, userType = null } = user;


    //Redirect to the desired page according to the userType
    if (isAuthenticated && userType === "Admin") {
        return <Redirect to={{ pathname: "/admin/dashboard" }} />
    } else if (isAuthenticated && userType === "Agency") {
        return <Redirect to={{ pathname: "/agency/devices" }} />
    } else if (isAuthenticated && userType === "Electrician") {
        return <Redirect to={{ pathname: "/electrician/devices" }} />
    }

    return (
        <Route
            exact
            path={path}
            render={(props) => {
                return <PublicMain {...props}>
                    <Component {...props} {...rest} />
                </PublicMain>
            }}
        ></Route>
    );
};




// Check Admin Routes
const AdminRoutes = ({ component: Component, ...rest }) => {
    let user = checkAuth('access-token');
    let { isAuthenticated, userType = null } = user;
    return <Route
        {...rest}
        render={(props) => {
            return isAuthenticated && userType === "Admin" ? (
                <Main  {...props} {...rest}>
                    <Component {...props} {...rest} />
                </Main>
            ) : (
                <Redirect to={{ pathname: "/login" }} />
            );
        }}
    />
};

// Check Agency Routes
const AgencyRoutes = ({ component: Component, ...rest }) => {
    let user = checkAuth('access-token');
    let { isAuthenticated, userType = null } = user;

    return <Route
        {...rest}
        render={(props) => {
            return isAuthenticated && userType === "Agency" ? (
                <Main  {...props} {...rest}>
                    <Component {...props} {...rest} />
                </Main>
            ) : (
                <Redirect to={{ pathname: "/login" }} />
            );
        }}
    />
};

// Check Electrician Routes
const ElectricianRoutes = ({ component: Component, ...rest }) => {
    let user = checkAuth('access-token');
    let { isAuthenticated, userType = null } = user;

    return <Route
        {...rest}
        render={(props) => {
            return isAuthenticated && userType === "Electrician" ? (
                <Main  {...props} {...rest}>
                    <Component {...props} {...rest} />
                </Main>
            ) : (
                <Redirect to={{ pathname: "/login" }} />
            );
        }}
    />
};

function Routes(props) {
    const dispatch = useDispatch();
    const { snackbar, confirmModal } = useSelector(state => state.common)
    const { open = false, messageType = 'success', message = '', timer = 4000 } = snackbar || {}
    const { isOpen } = confirmModal || {}

    // Close Notification bar and changed store's state also
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(commonActions.closeSnackbarsState())
    };
    return (
        <div>
            <Suspense fallback={<div className="update-circular-loader">
                <img src='/images/ep-loader.gif' alt='loader_gif' className="loader" />
            </div>}>
                <BrowserRouter >
                    <Switch>
                        {/* Public Routes */}
                        <PublicRoutes exact path="/" component={Login} />
                        <PublicRoutes exact path="/login" component={Login} />
                        <PublicRoutes exact path="/electrician-login" component={ElectricianLogin} />
                        <PublicRoutes exact path="/electrician-forgot-password" component={ElectricianForgotPassword} />
                        <PublicRoutes exact path="/electrician-reset-password" component={ElectricianResetPassword} />
                        <PublicRoutes exact path="/signup" component={Signup} />
                        <PublicRoutes exact path="/forgot-password" component={ForgotPassword} />
                        <PublicRoutes exact path="/reset-password" component={ResetPassword} />
                        <PublicRoutes exact path="/verify-otp" component={OTPInput} />

                        {/* Admin Routes */}
                        <AdminRoutes exact path="/admin/dashboard" component={Dashboard} valid={["electricity-advisor", "livelink", "safelink"]} />
                        <AdminRoutes exact path="/admin/electricity-advisor/devices" component={Devices} valid={["electricity-advisor"]} />
                        <AdminRoutes exact path="/admin/devices/view/:id" component={AdminDevice} valid={["electricity-advisor","all"]} />
                        <AdminRoutes exact path="/admin/livelink/devices" component={LiveLinkDevices} valid={["livelink"]} />
                        <AdminRoutes exact path="/admin/livelink/devices/view/:id" component={LiveLinkDevice} valid={["livelink","all"]} />
                        <AdminRoutes exact path="/admin/livelink/device/view/device-history/:id" component={LiveLinkDeviceHistory} valid={["livelink","all"]} />
                        <AdminRoutes exact path="/admin/device/view/firmware-history/:id" component={FirmwareHistory} valid={["electricity-advisor", "livelink", "safelink", "airlink","all"]} />
                        <AdminRoutes exact path="/admin/device/view/device-history/:id" component={DeviceHistory} valid={["electricity-advisor"]} />
                        <AdminRoutes exact path="/admin/safelink/device/view/device-history/:mac_address" component={SafelinkDeviceHistory} valid={["safelink","all"]} />
                        <AdminRoutes exact path="/admin/airlink/device/view/device-history/:serial_number" component={AirlinkDeviceHistory} valid={["airlink"]} />
                        <AdminRoutes exact path="/admin/device/view/impulse-rate-history/:id" component={ImpulseRateHistory} valid={["electricity-advisor", "livelink"]} />
                        <AdminRoutes exact path="/admin/device/view/update-rate-history/:id" component={UpdateRateHistory} valid={["electricity-advisor", "livelink"]} />
                        <AdminRoutes exact path="/admin/customers" component={Customers} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/customer/auto-sync/:id" component={AutoSync} valid={["electricity-advisor"]} />
                        <AdminRoutes exact path="/admin/customer/view/:id" component={ShowCustomer} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/customer/customer-email-history/:id" component={CustomerEmailHistory} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/customer/property/view/:id" component={Property} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/customer/property/view-history/:id" component={PropertyHistory} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/customer/property/property-timeline/:id" component={PropertyTimeLine} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/installer-agencies" component={InstallerAgencies} valid={["electricity-advisor", "airlink"]} />
                        <AdminRoutes exact path="/admin/installer-agency/view/:id" component={ShowAgency} valid={["electricity-advisor", "airlink"]} />
                        <AdminRoutes exact path="/admin/installer-agency/:id" component={AddAgency} valid={["electricity-advisor", "airlink"]} />
                        <AdminRoutes exact path="/admin/installer-agency" component={AddAgency} valid={["electricity-advisor", "airlink"]} />
                        <AdminRoutes exact path="/admin/integrations" component={Integrations} valid={["electricity-advisor", "airlink"]} />
                        <AdminRoutes exact path="/admin/integration/view/:id" component={ShowIntegration} valid={["electricity-advisor", "airlink"]} />
                        <AdminRoutes exact path="/admin/installer-agents" component={InstallerAgents} valid={["electricity-advisor", "airlink"]} />
                        <AdminRoutes exact path="/admin/installer-agent/view/:id" component={InstallerAgent} valid={["electricity-advisor", "airlink"]} />
                        <AdminRoutes exact path="/admin/installer-agent/view/device/:id" component={InstallerAgentDevice} valid={["electricity-advisor", "airlink"]} />
                        <AdminRoutes exact path="/admin/tariffs" component={Tariffs} valid={["electricity-advisor", "livelink"]} />
                        <AdminRoutes exact path="/admin/tariff" component={CreateTariffPage} valid={["electricity-advisor", "livelink"]} />
                        <AdminRoutes exact path="/admin/tariff/:id" component={CreateTariffPage} valid={["electricity-advisor", "livelink"]} />
                        <AdminRoutes exact path="/admin/electricity-retailers" component={ElectricityRetailer} valid={["electricity-advisor", "livelink"]} />
                        <AdminRoutes exact path="/admin/firmwares" component={Firmwares} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/OTAs" component={OTAs} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/OTA_History" component={OTAHistroy} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/rules" component={Rules} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/OTA-groups" component={OTAGroups} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/OTA-groups/:id" component={OTAGroup} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/aggregators" component={Aggregators} valid={["electricity-advisor", "airlink"]} />
                        <AdminRoutes exact path="/admin/electricians" component={Electrician} valid={["electricity-advisor"]} />
                        <AdminRoutes exact path="/admin/electrician/view/:id" component={ShowElectrician} valid={["electricity-advisor"]} />
                        <AdminRoutes exact path="/admin/electrician" component={AddElectrician} valid={["electricity-advisor"]} />
                        <AdminRoutes exact path="/admin/electrician/:id" component={AddElectrician} valid={["electricity-advisor"]} />
                        <AdminRoutes exact path="/admin/electricians-installers" component={ElectriciansAgents} valid={["electricity-advisor"]} />
                        <AdminRoutes exact path="/admin/electrician-installer/view/:id" component={ElectricianAgent} valid={["electricity-advisor"]} />
                        <AdminRoutes exact path="/admin/electrician-installer/device/view/:id" component={ShowElectricianDeviceHistoryDetails} valid={["electricity-advisor"]} />
                        <AdminRoutes exact path="/admin/weekly-reports" component={SuppressionList} valid={["electricity-advisor"]} />
                        <AdminRoutes exact path="/admin/safelink/devices" component={SafelinkDevices} valid={["safelink"]} />
                        <AdminRoutes exact path="/admin/safelink/device/view/:id" component={SafelinkDevice} valid={["safelink","all"]} />
                        <AdminRoutes exact path="/admin/smoke-alarm/devices" component={SmokeAlarmDevices} valid={["safelink"]} />
                        <AdminRoutes exact path="/admin/smoke-alarm/device/view/:id" component={SmokeAlarmDevice} valid={["safelink"]} />
                        <AdminRoutes exact path="/admin/airlink/devices" component={AirlinkDevices} valid={["airlink"]} />
                        <AdminRoutes exact path="/admin/airlink/device/view/:id" component={AirlinkDevice} valid={["airlink","all"]} />
                        <AdminRoutes exact path="/admin/profile" component={AdminProfile} valid={["electricity-advisor", "livelink", "safelink", "airlink"]} />
                        <AdminRoutes exact path="/admin/electricity-advisor/slider" component={Slider} valid={["electricity-advisor"]} />
                        <AdminRoutes exact path={["/admin/airlink/slider", "/admin/livelink/slider", "/admin/safelink/slider"]} component={Slider} valid={["airlink", "livelink", "safelink"]} />

                        {/* adming lightning routes */}

                        <AdminRoutes exact path="/admin/bulb/devices" component={LightingDevices} valid={["bulb"]} />
                        <AdminRoutes exact path="/admin/bulb/device/view/:id" component={LightingDevice} valid={["bulb","all"]} />
                        <AdminRoutes exact path="/admin/bulb/device/view/device-history/:serial_number" component={LightingDeviceHistory} valid={["bulb"]} />

                        {/* all tab routes */}

                        <AdminRoutes exact path="/admin/all/devices" component={AllTabDevices} valid={["all"]} />
                        
                        {/* admin heat pump routes */}

                        <AdminRoutes exact path="/admin/heat_pump/devices" component={HeatPumpDevices} valid={["heat_pump"]} />
                        <AdminRoutes exact path="/admin/heat_pump/models" component={HeatpumpModels} valid={["heat_pump"]} />
                        <AdminRoutes exact path="/admin/heat_pump/device/view/:id" component={HeatPumpDevice} valid={["heat_pump"]} />
                        <AdminRoutes exact path="/admin/heat_pump/device/view/device-history/:serial_number" component={HeatPumpDeviceHistory} valid={["heat_pump"]} />


                        {/* Agency Routes */}
                        <AgencyRoutes exact path="/agency/devices" component={AgencyDevices} />
                        <AgencyRoutes exact path="/agency/device/view/:id" component={ShowAgencyDevice} />
                        <AgencyRoutes exact path="/agency/dataforce-devices" component={DataForceAgencyDevices} />
                        <AgencyRoutes exact path="/agency/dataforce-device/:id" component={ShowElectricianDeviceHistoryDetails} />
                        <AgencyRoutes exact path="/agency/electricians" component={DataForceAgencyElectricians} />
                        <AgencyRoutes exact path="/agency/electrician/view/:id" component={ShowDataForceAgencyElectrician} />
                        <AgencyRoutes exact path="/agency/electricians-agents" component={ShowDataForceAgencyAgents} />
                        <AgencyRoutes exact path="/agency/electricians-agent/view/:id" component={ShowDataForceAgencyAgent} />
                        <AgencyRoutes exact path="/agency/installation-agents" component={InstallationAgents} />
                        <AgencyRoutes exact path="/agency/installation-agent/view/:id" component={ShowInstallationAgent} />
                        <AgencyRoutes exact path="/agency/installation-agent/:id" component={AddInstallationAgent} />
                        <AgencyRoutes exact path="/agency/installation-agent" component={AddInstallationAgent} />
                        <AgencyRoutes exact path="/agency/profile" component={Profile} />

                        {/* Electrician Routes */}
                        <ElectricianRoutes exact path="/electrician/devices" component={ElectricianDevices} />
                        <ElectricianRoutes exact path="/electrician/device/view/:id" component={ElectricianDevice} />
                        <ElectricianRoutes exact path="/electrician/installer-agents" component={ElectricianInstallerAgent} />
                        <ElectricianRoutes exact path="/electrician/installer-agent" component={AddElectricianInstallerAgent} />
                        <ElectricianRoutes exact path="/electrician/installer-agent/view/:id" component={ShowElectricianInstallerAgent} />
                        <ElectricianRoutes exact path="/electrician/installer-agent/:id" component={AddElectricianInstallerAgent} />
                        <ElectricianRoutes exact path="/electrician/profile" component={ElectricianProfile} />


                        <PublicRoutes component={NotFound} />

                    </Switch>
                </BrowserRouter>
            </Suspense>

            {/* Notification bar  */}
            <Snackbar open={open} autoHideDuration={timer} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} disableWindowBlurListener={true}>
                <Alert onClose={handleClose} severity={messageType}>
                    {message}
                </Alert>
            </Snackbar>

            {/* Delete Modal */}
            <CustomDialogBox
                open={isOpen}
            />

            {/* Token Expired Modal */}
            <TokenExpiredModal
                open={false}
                history={history}
            />

            { /* Custom Global Dialog */}
            <CustomGlobalDialog />



        </div>
    )
}

export default Routes
