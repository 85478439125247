import {
    loginAPI,
    getProfileAPI,
    updateProfileAPI,
    signupAPI,
    forgotPasswordAPI,
    resetPasswordAPI,
    refreshTokenAPI,
    resendSignupVerificationTokenAPI,
    electricianForgotPasswordAPI,
    electricianResetPasswordAPI,
    verifyUserAPI
} from './apis.js';
import *  as commonActions from "../common/actions"
import { errorMessage } from '../../utils'

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';


export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_ERROR = 'VERIFY_ERROR';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';


export function loginUser(data) {
    return dispatch => {
        dispatch({
            type: LOGIN_USER_REQUEST
        })
        return new Promise((resolve, reject) => {
            loginAPI(data).then(res => {
                dispatch({
                    type: LOGIN_USER_SUCCESS,
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: LOGIN_USER_ERROR
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
        })
    }
}

export function LoginAgencyUser(data) {
    return dispatch => {
        dispatch({
            type: VERIFY_REQUEST
        })
        return new Promise((resolve, reject) => {
            loginAPI(data).then(res => {
                const { token = '', info = {} } = res || {}
                dispatch({
                    type: VERIFY_SUCCESS,
                    payload: { access_token: token, userInfo: info }
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message, timer: 2000 }))
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: VERIFY_ERROR
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
        })
    }
}

export function verifyUser(data) {
    return dispatch => {
        dispatch({
            type: VERIFY_REQUEST
        })
        return new Promise((resolve, reject) => {
            verifyUserAPI(data).then(res => {
                const { token = '', info = {} } = res || {}
                dispatch({
                    type: VERIFY_SUCCESS,
                    payload: { access_token: token, userInfo: info }
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message, timer: 2000 }))
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: VERIFY_ERROR
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
        })
    }
}


export function login(data) {
    return dispatch => {
        dispatch({
            type: LOGIN_REQUEST
        })
        return new Promise((resolve, reject) => {
            loginAPI(data).then(res => {
                const { token = '', info = {} } = res || {}
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: { access_token: token, userInfo: info }
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message, timer: 2000 }))
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: LOGIN_ERROR
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
        })
    }
}

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export function signup(data) {
    return dispatch => {
        dispatch({
            type: SIGNUP_REQUEST
        })
        return new Promise((resolve, reject) => {
            signupAPI(data).then(res => {
                dispatch({
                    type: SIGNUP_SUCCESS,
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: SIGNUP_ERROR
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
        })
    }
}

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export function forgotPassword(data) {
    return dispatch => {
        dispatch({
            type: FORGOT_PASSWORD_REQUEST
        })
        return new Promise((resolve, reject) => {
            forgotPasswordAPI(data).then(res => {
                dispatch({
                    type: FORGOT_PASSWORD_SUCCESS,
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: FORGOT_PASSWORD_ERROR
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
        })
    }
}

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export function resetPassword(data, obj) {
    return (dispatch) => {
        dispatch({
            type: RESET_PASSWORD_REQUEST
        })

        return new Promise((resolve, reject) => resetPasswordAPI(data, obj)
            .then(res => {
                dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                    payload: res
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                return resolve(res);
            }).catch(err => {
                dispatch({
                    type: RESET_PASSWORD_FAILED,
                    payload: err
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            }))
    }
}

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILED = 'GET_USER_PROFILE_FAILED';
export const CLEAR_STORE_FOR_USER_PROFILE = 'CLEAR_STORE_FOR_USER_PROFILE'

export const getUserProfile = () => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_USER_PROFILE_REQUEST
        })
        getProfileAPI().then(res => {
            dispatch({
                type: GET_USER_PROFILE_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_USER_PROFILE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            reject(err)
        })
    })

export const clearStoreForUserProfile = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_STORE_FOR_USER_PROFILE
        })
    }
}

export const UPDATE_PROFILE_REQUEST = ' UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

export const updateUserProfile = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_PROFILE_REQUEST
        })
        updateProfileAPI(data).then(res => {
            dispatch({
                type: UPDATE_PROFILE_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_PROFILE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            reject(err)
        })
    })


export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED'


export const refreshToken = () => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: REFRESH_TOKEN_REQUEST
        })
        refreshTokenAPI().then(res => {
            const { token = '', info = {} } = res || {}
            dispatch({
                type: REFRESH_TOKEN_SUCCESS,
                payload: { access_token: token, userInfo: info }
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: REFRESH_TOKEN_FAILED
            })
            return reject(err)
        })
    })

export const RESEND_SIGNUP_VERIFICATION_EMAIL_TOKEN_REQUEST = 'RESEND_SIGNUP_VERIFICATION_EMAIL_TOKEN_REQUEST';
export const RESEND_SIGNUP_VERIFICATION_EMAIL_TOKEN_SUCCESS = 'RESEND_SIGNUP_VERIFICATION_EMAIL_TOKEN_SUCCESS';
export const RESEND_SIGNUP_VERIFICATION_EMAIL_TOKEN_FAILED = 'RESEND_SIGNUP_VERIFICATION_EMAIL_TOKEN_FAILED';

export const resendSignupVerificationToken = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: RESEND_SIGNUP_VERIFICATION_EMAIL_TOKEN_REQUEST
        })
        resendSignupVerificationTokenAPI(data).then(res => {
            dispatch({
                type: RESEND_SIGNUP_VERIFICATION_EMAIL_TOKEN_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: RESEND_SIGNUP_VERIFICATION_EMAIL_TOKEN_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            reject(err)
        })
    })

export const ELECTRICIAN_FORGOT_PASSWORD_REQUEST = 'ELECTRICIAN_FORGOT_PASSWORD_REQUEST';
export const ELECTRICIAN_FORGOT_PASSWORD_SUCCESS = 'ELECTRICIAN_FORGOT_PASSWORD_SUCCESS';
export const ELECTRICIAN_FORGOT_PASSWORD_ERROR = 'ELECTRICIAN_FORGOT_PASSWORD_ERROR';

export function electricanForgotPassword(data) {
    return dispatch => {
        dispatch({
            type: ELECTRICIAN_FORGOT_PASSWORD_REQUEST
        })
        return new Promise((resolve, reject) => {
            electricianForgotPasswordAPI(data).then(res => {
                dispatch({
                    type: ELECTRICIAN_FORGOT_PASSWORD_SUCCESS,
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: ELECTRICIAN_FORGOT_PASSWORD_ERROR
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            })
        })
    }
}

export const ELECTRICIAN_RESET_PASSWORD_REQUEST = 'ELECTRICIAN_RESET_PASSWORD_REQUEST';
export const ELECTRICIAN_RESET_PASSWORD_SUCCESS = 'ELECTRICIAN_RESET_PASSWORD_SUCCESS';
export const ELECTRICIAN_RESET_PASSWORD_FAILED = 'ELECTRICIAN_RESET_PASSWORD_FAILED';

export function electricianResetPassword(data, obj) {
    return (dispatch) => {
        dispatch({
            type: ELECTRICIAN_RESET_PASSWORD_REQUEST
        })

        return new Promise((resolve, reject) => electricianResetPasswordAPI(data, obj)
            .then(res => {
                dispatch({
                    type: ELECTRICIAN_RESET_PASSWORD_SUCCESS,
                    payload: res
                })
                dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
                return resolve(res);
            }).catch(err => {
                dispatch({
                    type: ELECTRICIAN_RESET_PASSWORD_FAILED,
                    payload: err
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                return reject(err)
            }))
    }
}

